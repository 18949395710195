"use client";

import SvgChevronDown from "@clayton-homes/ui-icons/24/chevron-down";
import SvgMapMarker2 from "@clayton-homes/ui-icons/24/map-marker-2";
import { ApiError } from "@clayton-homes/home-designer/util/has-api-error";
import { datadogRum } from "@datadog/browser-rum";
import Image from "next/image";
import { useSearchParams } from "next/navigation";
import { ReactElement } from "react";
import { Button, ButtonLink } from "./client.button";
import NoResultsImage from "/assets/no-results.webp";

interface Props {
	apiError: ApiError;
	children?: ReactElement;
	nested?: boolean;
}

export default function ModelError({
	apiError,
	children,
	nested = false,
}: Props) {
	const searchParams = useSearchParams();
	const zipCode = searchParams?.get("zipCode") || "";
	const noHomes = apiError.error === "missing_data";

	if (noHomes) datadogRum.addAction("No Results Found", apiError);
	else datadogRum.addError(apiError);

	return (
		<div className={`${nested ? "" : "bg-primary-50"} py-4`}>
			<div
				className={`${nested ? "" : "wrapper-lg"} my-4 flex flex-col items-center gap-20 text-primary-800 lg:flex-row`}
			>
				<div>
					<h2 className="text-headline-1 py-8">
						{noHomes
							? "Hmmm. We're not getting results."
							: "There was an error making your request."}
					</h2>
					{children ? (
						children
					) : (
						<>
							<p>
								We&apos;re sorry, but there are currently no homes available for
								the zip code <span className="font-bold">{zipCode}</span>.
							</p>
							<p className="py-4">
								Contact our team for more information about homes in your area
								or try a different zip code.
							</p>
							<div className="flex">
								<ButtonLink href={`/contact-us?zipCode=${zipCode}`}>
									Contact Us
								</ButtonLink>
								<Button
									className="text-paragraph bg-transparent font-bold !text-primary-500 hover:bg-transparent"
									onClick={() =>
										document.body
											.querySelector<HTMLButtonElement>(
												'[data-name="zip-code-dialog"]',
											)
											?.click()
									}
								>
									<SvgMapMarker2 className="w-6" /> {zipCode}
									<SvgChevronDown className="w-6" />
								</Button>
							</div>
						</>
					)}
				</div>
				<Image src={NoResultsImage} alt="No Results" className="mx-auto" />
			</div>
		</div>
	);
}
