"use client";

import { useSearchParams } from "next/navigation";
import { Suspense, useEffect, useState } from "react";
import ModelError from "../components/client.model-error";
import MainNav from "../components/main-nav/client";

export default function Error({
	error,
	reset,
}: {
	error: Error & { digest?: string };
	reset: () => void;
}) {
	const searchParams = useSearchParams();
	const zipCode = searchParams?.get("zipCode") || "";
	const [currentZipCode, setCurrentZipCode] = useState(() => zipCode);

	useEffect(() => {
		if (currentZipCode !== zipCode) reset();
		else setCurrentZipCode(zipCode);
	}, [zipCode, currentZipCode, reset]);

	return (
		<>
			<Suspense>
				<MainNav noBackgroundPage={false} />
			</Suspense>
			<ModelError
				apiError={{
					error: "api_error",
					error_message: error?.digest || "Unknown Error",
				}}
			/>
		</>
	);
}
